
import axiosInstance from "../helpers/axiosInstance";

const state = {
  cart: {}
};

const mutations = {
  SET_CART: (state, cart) => {
    state.cart = cart
  }
};

const actions = {
  //For a user to get all subscription products
  GET_ALL_SUBSCRIPTION_PRODUCTS({ }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/subscriptions/get-all-subscription-products")
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to add an item to cart
  ADD_SUBSCRIPTION_ITEM_TO_CART({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/subscriptions/add-subscription-item-to-cart", data)
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_CART", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to remove an item from cart
  REMOVE_SUBSCRIPTION_ITEM_FROM_CART({ commit }, cartItemId) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/subscriptions/remove-subscription-item-from-cart/${cartItemId}`)
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_CART", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get their subscription cart
  GET_SUBSCRIPTION_CART({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/subscriptions/get-subscription-cart")
        .then((res) => {
          const { success, data } = res.data;
          if (success == true) {
            commit("SET_CART", data)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to create a subscription order
  CREATE_SUBSCRIPTION_ORDER({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/subscriptions/create-subscription-order", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            commit("SET_CART", null)
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get all their subscription orders
  GET_ALL_SUBSCRIPTION_ORDERS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/subscriptions/get-all-subscription-orders", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get a subscription order
  GET_SUBSCRIPTION_ORDER({ }, orderId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/subscriptions/get-subscription-order/${orderId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get all their subscriptions
  GET_ALL_MY_SUBSCRIPTIONS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/subscriptions/get-all-my-subscriptions", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get a specific subscription
  GET_SUBSCRIPTION({ }, subscriptionId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/subscriptions/get-subscription/${subscriptionId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to assign a vehicle to a subscription
  ASSIGN_VEHICLE_TO_SUBSCRIPTION({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/subscriptions/assign-vehicle-to-subscription", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to remove a vehicle from a subscription
  REMOVE_VEHICLE_FROM_SUBSCRIPTION({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/subscriptions/remove-vehicle-from-subscription", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get assign vehicles to similar subscriptions
  GET_ASSIGNED_VEHICLES_TO_SIMILAR_SUBSCRIPTION({ }, subscriptionId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/subscriptions/get-vehicles-assigned-to-similar-subscription/${subscriptionId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to fetch the order invoice PDF
  GET_INVOICE_PDF({ }, subscriptionId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/subscriptions/generate-subscription-invoice-pdf/${subscriptionId}`, {
        responseType: 'blob', // Important to specify 'blob' response type
      })
        .then((res) => {
          if (res.status === 200) {
            // Resolve with the response data (blob) directly
            resolve(res.data);
          } else {
            reject({ message: 'Failed to fetch PDF' });
          }
        }).catch((err) => {
          reject(err.response ? err.response.data : { message: 'An error occurred' });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
