<template>
  <div>
    <modal
      name="confirm-alert"
      classes="modal-container"
      content-class="modal-content max-width-310"
      v-model="isModalVisible"
      @before-open="handSetParams"
      :click-to-close="true"
    >
      <div v-if="isModalVisible">
        <div class="bg-white border-radius-8">
          <ModalHeader name="confirm-alert" :title="title" />

          <div
            :class="`padding-bottom-0 padding-10 font-weight-400 font-size-14 line-height-20 ${
              isDanger && 'red'
            }`"
          >
            {{ message }}
          </div>

          <div class="d-flex justify-content-between margin-10">
            <button
              class="margin-0 btn max-width-100 bg-white black font-size-12 line-height-20 padding-6"
              type="button"
              @click="handleOnCancel"
            >
              No
            </button>
            <button
              :class="`margin-0 btn max-width-100 ${
                isDanger ? 'bg-red white' : 'bg-logo-black logo-yellow'
              } font-size-14 line-height-20 padding-6`"
              type="button"
              @click="handleOnConfirm"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import ModalHeader from "../utilityComponents/ModalHeader.vue";
export default {
  components: {
    ModalHeader,
  },

  data() {
    return {
      isModalVisible: false,
      title: "",
      message: "",
      onCancel: () => null,
      onConfirm: () => null,
      isDanger: false,
    };
  },
  methods: {
    handSetParams(event) {
      this.title = event.ref.params._value.title;
      this.message = event.ref.params._value.message;
      if (event.ref.params._value.onCancel) {
        this.onCancel = event.ref.params._value.onCancel;
      }
      this.onConfirm = event.ref.params._value.onConfirm;

      if (event.ref.params._value.isDanger) {
        this.isDanger = event.ref.params._value.isDanger;
      } else {
        this.isDanger = false;
      }
    },
    handleOnConfirm() {
      this.isModalVisible = false;
      this.onConfirm();
    },
    handleOnCancel() {
      this.isModalVisible = false;
      this.onCancel();
    },
  },
};
</script>

<style scoped></style>
